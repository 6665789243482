@import "colors";

$jetuc__font-folder-path: "css/fonts" !default;
$jetuc__img-folder-path: "img" !default;

//$jetuc__font-headline: Arial, Tahoma, sans-serif !default;
$jetuc__font-headline: "Roboto Condensed", Arial, sans-serif !default;
$jetuc__font-table-head: "Roboto Condensed", Arial, sans-serif !default;
$jetuc__font-table-body: "Roboto Condensed", Arial, sans-serif !default;
$jetuc__font-status: "Source Sans Pro", Arial, Tahoma, sans-serif !default;
$jetuc__font-default: Arial, Source Sans Pro, Tahoma, sans-serif !default;
$jetuc__font-mono-space: "Inconsolata", Arial, Tahoma, sans-serif !default;
$jetuc__font-mono-space-bold: "Inconsolata Bold", Arial, Tahoma, sans-serif !default;
$jetuc__font-screen: "Source Sans Pro", Arial, sans-serif !default;

@font-face {
    font-family: "Meteocon Custom";
    font-style: normal;
    font-weight: normal;
    src: url("#{$jetuc__font-folder-path}/Meteocon-custom.woff") format("woff"),
    url("#{$jetuc__font-folder-path}/Meteocon-custom.woff2") format("woff2");
}

@font-face {
    font-family: "ST-Icon-Font";
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: url("#{$jetuc__font-folder-path}/st-font.woff") format("woff"),
    url("#{$jetuc__font-folder-path}/st-font.woff2") format("woff2");
}

@font-face {
    font-family: "Inconsolata";
    font-weight: normal;
    src: url("#{$jetuc__font-folder-path}/Inconsolata-Regular.woff") format("woff");
}

@font-face {
    font-family: "Inconsolata Bold";
    font-weight: bold;
    src: url("#{$jetuc__font-folder-path}/Inconsolata-Bold.woff") format("woff");
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("#{$jetuc__font-folder-path}/RobotoCondensed-Regular-webfont.woff") format("woff");
}

@font-face {
    font-family: "Roboto Condensed";
    font-weight: bold;
    src: url("#{$jetuc__font-folder-path}/RobotoCondensed-Bold-webfont.woff") format("woff");
}

@font-face {
    font-family: "Source Sans Pro";
    font-weight: 600;
    src: url("#{$jetuc__font-folder-path}/SourceSansPro-Semibold.ttf.woff") format("woff"),
    url("#{$jetuc__font-folder-path}/SourceSansPro-Semibold.ttf.woff2") format("woff2");
}

@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    src: url("#{$jetuc__font-folder-path}/SourceSansPro-Regular.ttf.woff") format("woff"),
    url("#{$jetuc__font-folder-path}/SourceSansPro-Regular.ttf.woff2") format("woff2");
}

@font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: bold;
    src: url("#{$jetuc__font-folder-path}/SourceSansPro-Bold.ttf.woff") format("woff"),
    url("#{$jetuc__font-folder-path}/SourceSansPro-Bold.ttf.woff2") format("woff2");
}

@mixin fontButtons {
    font-family: "Roboto Condensed", Tahoma, sans-serif;
    font-weight: bold;
}

@mixin fontMonoSpace {
    font-family: "Inconsolata", Arial, Tahoma, sans-serif;
}

@mixin fontDefault {
    font-family: Arial, "Century Gothic", Source Sans Pro, Tahoma, sans-serif;
}

@mixin fontScreen {
    font-family: "Source Sans Pro", Arial, Tahoma, Helvetica, sans-serif;
}

@mixin fontNarrow {
    font-family: "Roboto Condensed", Arial, sans-serif;
}

@mixin fontNarrower {
    font-family: Arial, "Roboto Condensed", "Inconsolata", Tahoma, Arial, sans-serif;
}

$phone-portrait-view: "(max-width: 499px)" !default;
$phone-landscape-view: "(min-width: 499px) and (max-width: 767px)" !default;
//$mobile-view: "(max-width: 899px)" !default;
//$desktop-view: "(min-width: 900px)" !default;

// currently desktop view only while mobile navigation is still under development
// TO DO: still open issue for LUEJOH for a necessary modifier for mobile view
//$mobile-view: "(max-width: 1px)" !default;
//$desktop-view: "(min-width: 1px)" !default;
$mobile-view: "(max-width: 899px)" !default;
$desktop-view: "(min-width: 900px)" !default;
$smalldesktop-view: "(max-width: 1400px)" !default;

/////////////////////////////////////////////////
// CHANGE SCENE ANIMATION                     //
////////////////////////////////////////////////
@mixin change-scene-animation {

    // Scene 1 fades out
    // animation: scene-out 0.3s forwards;
    @keyframes scene-out {
        0% {
            height: auto;
            transform: scale(1);
            opacity: 1;
            -webkit-filter: blur(5px);
            filter: blur(5px);

        }

        100% {
            height: 0;
            overflow: hidden;
            transform: scale(1.5);
            opacity: 0;
            -webkit-filter: blur(5px);
            filter: blur(5px);
        }
    }

    // Scene 1 start, Scene 1 fades back in
    // animation: scene-back-in .2s forwards;
    @keyframes scene-back-in {
        0% {
            transform: scale(1.5);
            opacity: 0;
            -webkit-filter: blur(5px);
            filter: blur(5px);
        }

        100% {
            height: auto;
            transform: scale(1);
            opacity: 1;
            -webkit-filter: blur(0);
            filter: blur(0);

        }
    }

    // Scene 2 fades in
    // Scene 2 nees these base styles:
    // height: 0;
    // overflow: hidden;
    //animation: scene-in 0.3s forwards;
    @keyframes scene-in {
        0% {
            height: auto;
            transform: scale(.7) translate(0, -50px);
            opacity: 0;
        }

        100% {
            height: auto;
            transform: scale(1) translate(0, 0);
            opacity: 1;
        }
    }
}

@function textColor($color) {
    @if (lightness($color) < 20) {
        @return #000;
    }

    @else {
        @return #fff;
    }
}

@mixin competition-name {
    .event-name {
        margin-right: .5em;
        color: var(--mis__accent);
        font-size: .9em;
        font-weight: bold;
    }

    .phase-name {
        min-width: 30px;
        margin-right: .5em;
        font-size: .9em;
        font-weight: bold;
    }

    .unit-name {
        font-size: .8em;

        &:empty {
            display: none;
        }
    }
}

@mixin row-highlight($row-highlight-color) {
    position: relative;

    .component__button-link {
        border-color: $row-highlight-color;
        background-color: $row-highlight-color;
        color: #fff;
    }

    .component__button {
        transform: translateY(1px);
        border-color: $row-highlight-color;
        background-color: $row-highlight-color;
        color: #fff;
    }

    .linkIconButtonComponent {
        border-color: $row-highlight-color;
        background-color: $row-highlight-color;
        color: #fff;
    }

    outline: 1px solid $row-highlight-color;

}

$lr__text-colorDefault: $jetuc__text-color;

@mixin selectedColorMixin($color, $colorLight, $lr__text-color: $lr__text-colorDefault) {

    &:nth-child(odd),
    &:nth-child(even) {
        border-color: #fff;

        td {
            border-color: #fff;
        }

        &.xg--selected {
            background: $colorLight !important;
        }

        .dark,
        .rank,
        .pos {
            background-color: darken($colorLight, 10%) !important;
            font-weight: bold;
            text-decoration: dashed;
        }

        .component__button.icon--info {
            border-color: #fff;
            background-color: $color;
            color: #fff;
            font-weight: bold;
        }
    }
}

@mixin opacity($value) {
    opacity: $value;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$value * 100})";
    -khtml-opacity: $value;
    -moz-opacity: $value;
    filter: alpha(opacity=$value * 100);
}

@mixin columnBorderWide($color, $position: left) {
    position: relative;
    overflow: visible;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: -1px;
        left: -1px;
        width: 2px;
        background: $color;

        @if $position ==right {
            right: -1px;
            left: auto;
        }
    }
}

@mixin flag {
    padding-left: 25px !important;
    background-repeat: no-repeat, repeat;
    background-position: left center, 0 0;
}

@mixin ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

$horDefault: 0 !default;
$verDefault: 1px !default;
$blurDefault: 1px !default;
$spreadDefault: 0 !default;
$rDefault: 0 !default;
$gDefault: 0 !default;
$bDefault: 0 !default;
$opacityDefault: .1 !default;

@mixin boxShadow($hor: $horDefault, $ver: $verDefault, $blur: $blurDefault, $spread: $spreadDefault, $r: $rDefault, $g: $gDefault, $b: $bDefault, $opacity: $opacityDefault) {
    box-shadow: $hor $ver $blur $spread rgba($r, $g, $b, $opacity);
}



@mixin component-button-style {
    position: relative;
    border: 1px solid $jetuc__button-border;
    background-color: $jetuc__button-bg;
    color: $jetuc__button-text;
    font-family: $jetuc__button-font;
    font-size: .9em;
    text-transform: uppercase;
    cursor: pointer;

    &.xg--selected {
        border-color: $jetuc__button-active-border;
        background-color: $jetuc__button-active-bg;
        color: $jetuc__button-active-text;

        &:hover {
            border-color: $jetuc__button-active-border;
            background-color: $jetuc__button-active-bg;
            color: $jetuc__button-active-text;
        }
    }

    &:hover {
        border-color: $jetuc__button-hover-border;
        background-color: $jetuc__button-hover-bg;
        color: $jetuc__button-hover-text;
    }

}

@mixin position-absolute($top: 0, $right: 0, $bottom: 0, $left: 0) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin main-frame-margin-absolute {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
}

@mixin positionRelative {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
}

@mixin noMatch {
    margin: 20px 0 0 5px;
    font-size: 16px;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
}

$transitionDefault: all !default;
$transitionTime: .4s !default;

@mixin transition($type: $transitionDefault, $time: $transitionTime) {
    transition: $type ease-in-out $time;
}

@mixin columnNat {
    width: 55px;
    padding: 0;
    background-repeat: no-repeat;
    background-position: 4px 7px;
    background-size: auto 14px;
    font-family: $jetuc__font-mono-space;
    font-size: .9em;
    font-weight: bold;
    text-align: left;
    text-indent: 29px;

    &:empty {
        &:before {
            display: none;
        }
    }

    &:before {
        content: "";
        position: absolute;
        top: 6px;
        left: 3px;
        width: 23px;
        height: 16px;
        border: 1px solid #fff;
        box-shadow: 0 0 1px #000;
    }

    &.narrow {
        width: 27px;
        height: 2.5em;
        padding-top: 9px;
        background-position: 3px 3px !important;
        font-family: $jetuc__font-mono-space;
        font-size: 12px;
        text-align: center;
        text-indent: 0;

        &:before {
            top: 2px;
            left: 2px;
        }
    }
}

@mixin columnNatNarrow {
    width: 27px !important;
    height: 2.5em;
    padding-top: 9px !important;
    background-repeat: no-repeat;
    background-position: 3px 3px !important;
    background-size: auto 14px;
    font-family: $jetuc__font-mono-space;
    font-size: .8em !important;
    line-height: 2.5em;
    text-align: center !important;
    text-indent: 0 !important;

    &:before {
        top: 2px;
        left: 2px;
    }
}

@mixin recordMarker {
    padding: .1em;
    background: $jetuc__dark;
    color: $jetuc__dark-text-over;
    font-family: $jetuc__font-status;
    font-size: .74em;
    font-weight: normal;
    line-height: 1;

    &:empty {
        display: none;
    }
}

@mixin medalStyles {
    &.gold {
        background-color: rgba($jetuc__gold, .15);
    }

    &.bronze {
        background-color: rgba($jetuc__bronze, .2);
    }

    &.silver {
        background-color: rgba($jetuc__silver, .15);
    }
}

@mixin xMedalStyles {
    .xg--medal-gold {
        background-color: $jetuc__gold;
    }

    .xg--medal-silver {
        background-color: $jetuc__silver;
    }

    .xg--medal-bronze {
        background-color: $jetuc__bronze;
    }
}

@mixin standard-table-floating-footer($header-height, $footer-height) {
    .standard-table_header {
        flex: 0 0 $header-height;
    }

    .standard-table-scroller {
        flex: 0 1 auto;
        height: auto;
    }

    .standard-table_footer {
        flex: 1;
        min-height: $footer-height;
    }
}

@mixin nat--default-td {
    display: flex;
    flex: 0 0 55px;
    align-items: center;
    width: 55px;
    padding: 0 1px;
    text-indent: 0;

    .nat-flag {
        position: relative;
        width: auto;
        max-height: 14px;
        margin: 0 2px 0 0;
        border: 1px solid #fff;

        background: #fff;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, .4);

        @media #{$desktop-view} {
            max-width: 21px;
        }

        &[src=""] {
            display: none;
        }

    }

    .nat-code {
        padding-left: 1px;
        font-family: $jetuc__font-mono-space-bold;
        font-size: 1em;
    }

    @media #{$mobile-view} {
        flex: 0 0 3.667em;
        width: 3.667em;
    }
}

@mixin nat--default-td-narrow {
    display: flex;
    position: relative;
    flex: 0 0 27px;
    flex-direction: column;
    align-items: center;
    width: 27px;
    padding: 0;

    .nat-flag {
        margin: 0;
    }

    .nat-code {
        flex: 1;
        width: 100%;
        min-height: 1em;
        max-height: 1em;
        padding: 0;
        font-family: $jetuc__font-mono-space-bold;
        font-size: .85em;
        line-height: 1.1em;
        text-align: center;
    }
}

@mixin nat--default {
    display: flex;
    align-items: center;

    .nat-flag {
        position: relative;
        max-width: 21px;
        max-height: 14px;
        margin-right: 3px;
        border: 1px solid #fff;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, .4);
    }

    .nat-code {
        font-family: $jetuc__font-mono-space-bold;
    }
}

@mixin evenRow {
    background-color: $jetuc__table-row-bg-even;

    td {

        @include medalStyles;

        &.dark {
            background-color: $jetuc__table-row-dark-bg-even;
            @include medalStyles;
        }

        &.highlight {
            background: $jetuc__highlight-bg;
            font-weight: bold;
        }

        &.positive {
            background-color: $jetuc__live-bg;
        }

        &.negative {
            background-color: $jetuc__negative-bg;
        }
    }
}

@mixin oddRow {
    background-color: $jetuc__table-row-bg-odd;

    td {
        @include medalStyles;

        &.dark {
            background-color: $jetuc__table-row-dark-bg;
            @include medalStyles;
        }

        &.highlight {
            background: $jetuc__highlight-bg;
            font-weight: bold;
        }

        &.positive {
            background-color: $jetuc__live-bg;
        }

        &.negative {
            background-color: $jetuc__negative-bg;
        }
    }
}


@mixin columnStyles {
    &:last-child {
        border-right: none;
    }

    .athleteName {
        position: relative;
    }

    &.td--value-bg {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            display: block;
            width: 90%;
            height: 18px;
            background: #717171;
            color: #fff;
            line-height: 17px;

            &:empty {
                display: none;
            }
        }
    }

    &.rank--irm,
    &.rank-irm {
        flex: 0 0 35px;
        width: 35px;
        padding: 0;
        font-weight: bold;
        text-align: center;
    }

    &.rank-irm {
        display: flex;
        flex-direction: column;

        .irm {
            font-weight: normal;
        }

        .rk {
            &:not(:empty)~.irm {
                font-size: .75em;
            }
        }

        &.xg--photo-finish {
            .irm {
                &:before {
                    content: $jetuc__icon-photo;
                    font: normal 12px ST-Icon-Font;
                }
            }

            .rk {
                &:not(:empty)~.irm:not(:empty) {
                    font-size: .75em;

                    &:before {
                        padding-right: 3px;
                        font-size: .8em;
                    }
                }

                &:empty~.irm {
                    display: flex;
                    flex-direction: column-reverse;

                    &:before {
                        font-size: .8em;
                    }
                }
            }

            &:after {
                display: none;
            }
        }
    }

    &.rank {
        flex: 0 0 35px;
        width: 35px;
        font-weight: bold;
        text-align: center;
    }

    &.bib {
        flex: 0 0 35px;
        text-align: center;

        &.dark {
            @include fontMonoSpace;
        }
    }

    &.bibNat {
        flex: 0 0 55px;
        width: 55px;

        .nat {
            width: auto;
            background-position: 0 7px;
            text-indent: 24px;
        }

        .bib {
            position: absolute;
            top: 18px;
        }
    }

    &.nat,
    .nat {
        @include columnNat;
    }

    &.nat--default {
        @include nat--default-td;
    }

    &.name,
    .name,
    &.teamName {
        font-size: 1em;
        font-weight: bold;
    }

    &.name {
        display: flex;
        min-width: 100px;
        max-width: 300px;

        .athleteName {}
    }

    &.W,
    .W {
        position: relative;

        &:before {
            @include position-absolute;
            content: "F";
            text-align: center;
        }
    }

    &.M,
    .M {
        position: relative;

        &:before {
            @include position-absolute;
            content: "M";
            text-align: center;
        }
    }
}


@mixin qualified {
    display: inline-block;
    padding: 2px 3px;
    border-radius: 2px;
    background: $jetuc__accent;
    color: $jetuc__accent-text;
    font-size: .85em;
    font-weight: normal;
    line-height: 1;
}

@mixin qualifiedColumn {
    color: $jetuc__accent;
    font-weight: bold;
}

@mixin qualifiedBox {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        display: inline-block;
        padding: 2px 3px;
        border: 1px solid #c3c3c3;
        border-radius: 2px;
        background: $jetuc__accent;
        color: $jetuc__accent-text;
        font-size: .85em;
        font-weight: normal;
        line-height: 1;

        &:empty {
            display: none;
        }
    }

}

@mixin qualifiedMarker {
    display: inline-block;
    padding: 2px 3px;
    border: 1px solid #c3c3c3;
    border-radius: 2px;
    background: $jetuc__accent;
    color: $jetuc__accent-text;
    font-size: .85em;
    font-weight: normal;
    line-height: 1;

    &:empty {
        display: none;
    }
}

@mixin statusOfficial {
    &:before {
        border: 1px solid #fff;
        background: $jetuc__finished;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, .4);
    }
}

@mixin statusCancelled {
    &:before {
        border: 1px solid #fff;
        background: $jetuc__declined;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, .4);
    }
}

@mixin statusLive {
    &:before {
        border: 1px solid #fff;
        background: $jetuc__live;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, .4);
    }
}

@mixin statusUnOfficial {
    &:before {
        border: 1px solid $jetuc__finished;
        background: $jetuc__finished-bg;
        box-shadow: none;
    }
}

@mixin statusComingUp {
    &:before {
        border: 1px solid #fff;
        background: $jetuc__coming-up;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, .4);
    }
}

@mixin statusInterrupted {
    &:before {
        content: "\f04c";
        width: 1.2em;
        height: 1.2em;
        padding: .06em;
        background: rgba(255, 255, 255, .8);
        color: $jetuc__declined;
        font: normal 1em ST-Icon-Font;
        text-indent: 1px;
    }
}

@mixin statusBreak {
    &:before {
        content: "\f04c";
        width: 1.2em;
        height: 1.2em;
        padding: .06em;
        background: rgba(255, 255, 255, .8);
        color: $jetuc__live;
        font: normal 1em ST-Icon-Font;
        text-indent: 1px;
    }
}

@mixin statusDelayed {
    &:before {
        content: "\e91d";
        font: normal 1em ST-Icon-Font;
        text-indent: 0;
    }
}

@mixin statusPostponed {
    &:before {
        content: "\e91d";
        width: 1.2em;
        height: 1.2em;
        padding: .06em;
        background: rgba(255, 255, 255, .8);
        color: $jetuc__declined;
        font: normal 1em ST-Icon-Font;
    }
}

@mixin statusScheduled {
    &:before {
        content: "\f017";
        font: normal 1.3em/1 ST-Icon-Font;
    }
}

@mixin statusRescheduled {
    &:before {
        content: "\f017";
        width: .95em;
        padding: .02em;
        background: rgba(255, 255, 255, .8);
        color: $jetuc__declined;
        font: normal 1.3em/1 ST-Icon-Font;
    }
}

@mixin statusProtested {
    &:before {
        content: "\f071";
        width: 1.2em;
        height: 1.2em;
        padding: .1em .06em .06em .06em;
        background: rgba(255, 255, 255, .8);
        color: $jetuc__declined;
        font: normal 1em/1 ST-Icon-Font;
    }
}

@mixin statusIcons {

    &[class*="official"] {
        @include statusOfficial;
    }

    &[class*="cancel"] {
        @include statusCancelled;
    }

    &[class*="unOfficial"] {
        @include statusUnOfficial;
    }

    &[class*="unofficial"] {
        @include statusUnOfficial;
    }

    &[class*="finished"] {
        @include statusOfficial;
    }

    &[class*="running"],
    &.live {
        @include statusLive;
    }

    &[class*="coming"] {
        @include statusComingUp;
    }

    &[class*="interrupted"] {
        @include statusInterrupted;
    }

    &[class*="break"] {
        @include statusBreak;
    }

    &[class*="delayed"] {
        @include statusDelayed;
    }

    &[class*="postponed"] {
        @include statusPostponed;
    }

    &[class*="scheduled"] {
        @include statusScheduled;
    }

    &[class*="rescheduled"] {
        @include statusRescheduled;
    }

    &[class*="protested"] {
        @include statusProtested;
    }
}

@mixin is-captain {
    .athlete-name {
        position: relative;

        &:before {
            content: "C";
            display: inline-block;
            margin-right: 2px;
            padding: 1px 4px;
            border-radius: 2px;
            background: $jetuc__accent;
            color: $jetuc__dark-text-over;
            font-weight: normal;
        }
    }
}

@mixin messageBig {
    margin-top: 31px;
    font-family: $jetuc__font-headline;
    font-size: 31px;
    font-weight: bold;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
}

@mixin message {
    margin: 10px;
    font-family: $jetuc__font-headline;
    font-size: 17px;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
}

@mixin nat-flag-style {
    border: 1px solid #fff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, .4);
}


@mixin overlayBackground {
    display: block !important;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .2);
}


@mixin fadeInAnimation {
    @-webkit-keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}

@mixin textGlow($highlight) {
    text-shadow: 0 0 .5px #fff, 0 0 1px #fff, 0 0 2px #fff, 0 0 8px $highlight, 0 0 10px $highlight, 0 0 10px $highlight, 0 0 20px $highlight, 0 0 40px $highlight;
}

@mixin cardShadow {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, .1);
}

@mixin shadowInsetLeft {
    box-shadow: inset 10px 0 10px -10px rgba(0, 0, 0, .15);
}

@mixin deactivated-column($columnName) {
    thead {
        .#{$columnName} {
            opacity: .5;
        }
    }

    tbody {
        .#{$columnName} {
            background: repeating-linear-gradient(45deg,
                    $jetuc__table-row-bg-even,
                    $jetuc__table-row-bg-even 4px,
                    darken($jetuc__table-row-bg-even, 5%) 4px,
                    darken($jetuc__table-row-bg-even, 5%) 8px);
            color: rgba(#000, .4);
        }
    }
}

@mixin section-style {
    outline: 1px solid $jetuc__section-border;
    background-color: $jetuc__section-bg;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, .1);
}

@mixin card-style {
    border: 2px solid #fff;
    background: $jetuc__section-bg;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, .05), 0 0 1px 0 rgba(0, 0, 0, .05), 0 2px 5px 0 rgba(0, 0, 0, .1);
}

@mixin card-style-white {
    background: #fff;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, .05), 0 0 1px 0 rgba(0, 0, 0, .05), 0 2px 5px 0 rgba(0, 0, 0, .1);
}

@mixin card-large-style {
    border: 2px solid #fff;
    background: $jetuc__section-bg;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1), 0 0 2px 0 rgba(0, 0, 0, .15), 0 4px 10px 0 rgba(0, 0, 0, .2);
}


@mixin caption-style {
    background-color: $jetuc__caption-bg;
    color: $jetuc__caption-text;
}




@mixin event-overview-open-tablet {
    @media (max-width: 1100px) {
        .event-overview-container.x--show-navi & {
            @content;
        }
    }
}

@mixin non-grid-styles {
    @supports not (grid-auto-flow: column) {
        @content; // for Edge Versions 12 - 15
    }
}

@mixin darken-background($color, $value) {
    background: linear-gradient(to top,
            rgba(0, 0, 0, $value),
            rgba(0, 0, 0, $value))$color;
}

@mixin lighten-background($color, $value) {
    background: linear-gradient(to top,
            rgba(255, 255, 255, $value),
            rgba(255, 255, 255, $value))$color;
}

@mixin alternate-background {
    &:nth-child(odd) {
        background-color: rgba(#000, .03);

        .xg--color-mode_dark & {
            background-color: rgba(#fff, .2);
        }
    }
}